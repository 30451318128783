<script>
import InfoCard from '../components/InfoCard.vue';

export default {
  components: {
    InfoCard,
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  computed: {
    infoCards() {
      return this.block?.fields?.InfoCards || [];
    },
  },
  methods: {
    numberOfCardsPerRowClass(idx) {
      if (this.infoCards.length <= this.maxCardsPerRow) {
        return `info-cards__${this.infoCards.length}-cards`;
      }

      if (this.infoCards.length - idx <= this.cardsOnLastRow) {
        return `info-cards__${this.cardsOnLastRow}-cards`;
      }

      return `info-cards__${this.maxCardsPerRow}-cards`;
    },
  },
  created() {
    this.maxCardsPerRow = 4;
    this.cardsOnLastRow = this.infoCards.length % this.maxCardsPerRow;
  },
};
</script>

<template>
  <div class="info-cards__container">
    <info-card
      v-for="( card, idx ) in infoCards"
      :class="numberOfCardsPerRowClass(idx)"
      :key="card"
      :card="card"
    />
  </div>
</template>

<style>
  .info-cards__container {
    max-width: calc(var(--header-max-width) + 20px);
    margin: auto;
    padding: 20px 10px;
    overflow: hidden;
    display: flex;
    gap: 45px;
    flex-direction: column;
  }

  @media (--tabletAndDesktop) {
    .info-cards__container {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
</style>

<script>
import { addClass } from "@/helpers/text-editor-helper.js";
import * as parse5 from "parse5";


export default {
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  data() {
    const lowResolutionImage =
      this.card.InfoCardMedia?.id &&
      (this.card.InfoCardMedia?.templateId || "") === "Image" &&
      this.$toLitiumMediaUrl(this.card.InfoCardMedia.id, { maxWidth: 50 });

    return {
      highResolutionImage: null,
      imgUrl: lowResolutionImage,
      isImageDownloaded: false,
    };
  },
  mounted() {
    if (!this.imgUrl) {
      return;
    }

    const card = this.card?.InfoCardMedia || {
      width: 0,
      height: 0,
    };

    const container = this.$refs.imageContainer;
    const imageAspectRatio = card.width / card.height;
    const containerAspectRatio = container.clientWidth / container.clientHeight;

    this.highResolutionImage = this.$toLitiumMediaUrl(card.id, {
      ...(imageAspectRatio < containerAspectRatio
        ? { maxWidth: container.clientWidth }
        : { maxHeight: container.clientHeight }),
    });

    this.$nextTick(() => {
      const img = new Image();

      img.onload = () => {
        this.imgUrl = this.highResolutionImage;
        this.isImageDownloaded = true;
      };

      img.src = this.highResolutionImage;
    });
  },
  computed: {
    cardText() {
      if (!this.card.InfoCardText) return "";
      return addClass(this.card.InfoCardText || "", "info-card");
    },
    videoUrl() {
      return this.card.InfoCardMedia?.templateId === "Movie"
        ? this.$toLitiumMediaUrl(this.card.InfoCardMedia.id)
        : undefined;
    },
    isValidHtml() {
      try {
        const fragment = parse5.parseFragment(this.cardText);
        const serialized = parse5.serialize(fragment);
        return serialized;
      } catch (err) {
        console.warn(err);
        return false;
      }
    },
    cardLink() {
      return this.card.LinkToCustomUrl
              || this.card.LinkToPage?.href
              || this.card.LinkToCategory?.href
              || this.card.LinkToProduct?.href
              || undefined;
    },
    cardLinkTargetTab() {
      const shouldTargetNewTab = this.card.LinkTargetNewTab;
      return this.cardLink && shouldTargetNewTab ? '_blank' : undefined;
    },
  },
};
</script>
<template>
  <div class="info-card__container">
    <component
      :is="cardLink ? 'router-link' : 'div'"
      :to="cardLink"
      :target="cardLinkTargetTab"
      class="info-card__image-container"
      ref="imageContainer"
    >
      <div class="info-card__image-spacer"></div>
      <img v-if="imgUrl" class="info-card__image" :src="imgUrl" />
      <video
        v-if="videoUrl"
        class="info-card__video"
        autoplay
        muted
        loop
        playsinline
        :src="videoUrl"
      />
    </component>
    <div v-if="isValidHtml" class="info-card__text text-editor-content" v-html="cardText"></div>
  </div>
</template>
<style>
.info-card__container {
  flex: 1;
}

.info-card__image-container {
  position: relative;
  overflow: hidden;
  display: block;
}

.info-card__image-spacer {
  padding-top: 81.67%;
  background: var(--color-neutrals-45);
}

.info-card__image,
.info-card__video {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info-card__text {
  margin-top: 15px;
  color: var(--color-black);
}

.info-card__text-element {
  margin: 0;
}

p.info-card__text-element {
  line-height: 24px;
}

h1.info-card__text-element,
h2.info-card__text-element,
h3.info-card__text-element,
h4.info-card__text-element {
  margin: 0 0 15px;
  text-align: left;
  line-height: 32px;
}

@media (--tabletAndDesktop) {
  .info-cards__1-cards .info-card__image-spacer,
  .info-cards__2-cards .info-card__image-spacer {
    padding-top: 48.07%;
  }

  .info-cards__3-cards .info-card__image-spacer {
    padding-top: 65.63%;
  }

  .info-cards__4-cards .info-card__image-spacer {
    padding-top: 91.165%;
  }

  .info-card__container {
    max-width: calc(50% - (45px / 2));
    flex: 1 1 calc(25% - 45px);
  }
}
</style>
